@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@fortawesome/fontawesome-free/css/all.min.css";
@import "./fonts.css";




